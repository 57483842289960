import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#5392F9",
    },
    secondary: {
      main: "#707070",
    },
    text: {
      icon: "#777777",
      title: "#3D5170",
    },
    common: {
      white: "#E9ECEF",
    },
    error: {
      main: "#e91e63",
    },
  },
  typography: {
    fontFamily: "Roboto Mono",
    color: "#707070",
    h1: {
      color: "#707070",
      fontSize: 40,
      fontWeight: 700,
      padding: "2rem 0",
    },
    h2: {
      color: "#707070",
      fontSize: 25,
      fontWeight: 900,
    },
    h3: {
      color: "#707070",
    },
    h4: {
      color: "#707070",
    },
    h5: {
        color: "#707070",
    },
    // 大きいサブタイトル
    subtitle1: {},
    // 小さいサブタイトル
    subtitle2: {
      color: "#707070",
      fontSize: 14,
    },
    // 本文
    body1: {
      color: "#707070",
      fontSize: 16,
      lineHeight: 2,
    },
    // 細かい本文
    body2: {
      color: "#707070",
      fontSize: 16,
      lineHeight: 1.75,
    },
  },
});

export default theme;