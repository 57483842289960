import "./App.css";
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CheckIcon from "@material-ui/icons/Check";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import indigo from "@material-ui/core/colors/indigo";
import red from "@material-ui/core/colors/red";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  panel: {
    backgroundColor: "#F4F4F4",
    borderRadius: "16px!important",
  },
  textfield: {
    backgroundColor: "#FFFFFF",
    borderRadius: "16px",
  },
  bg_img: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/search_img.jpg)`,
    height: "1500px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center 0",
    "&::before": {
      backgroundColor: "black",
      opacity: "0.4",
      position: "absolute",
      top: "0",
      right: "0",
      bottom: "0",
      left: "0",
      height: "1500px",
      content: '""',
    },
  },
  alltext: {
    position: "absolute",
    width: "100%",
  },
  text: {
    color: "white",
  },
  textex: {
    color: "white",
    textAlign: "left",
  },
  textred: {
    color: "red",
    fontWeight: "bold",
    textAlign: "left",
  },
  text1: {
    color: "white",
    verticalAlign: "bottom",
    marginLeft: "1rem",
  },
  ok: {
    textAlign: "center",
  },
  tl: {
    textAlign: "right",
  },
}));

export default function Check() {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [error, setError] = useState("入力してください");
  const [copytext, setCopy] = useState("Copy");
  var studentnumber = new RegExp(/^\d{8}\s{0,1}\S{1,10}\s{0,1}\S{1,10}$/);
  var zenkaku = new RegExp(/[０-９]/);
  var mozisuu = new RegExp(/\d{3}\D{0,3}\s/);
  function docheck(test) {
    setValue(test);
    let a = value.split("\n")[0];
    if (studentnumber.test(a))
      if (!zenkaku.test(value)) {
        if (mozisuu.test(value.replace(a, ""))) {
          setError("");
        } else setError("文字数は記入しましたか？");
      } else setError("全角の数字が入っています。英数字は半角です。");
    else setError("冒頭は学籍番号８桁、フルネームです。");
  }
  const handleChange = (event) => {
    docheck(event.target.value);
  };
  const handleClick = () => {
    docheck(document.getElementById("textareas").value);
  };
  const copy = () => {
    document.getElementById("textareas").select();
    document.execCommand("copy");
    setCopy(
      "コピー完了しました！！もう一度コピーしたい場合はまたこのボタンを押してください！"
    );
  };
  return (
    <div className={classes.bg_img}>
      <Box px={8} pt={8} className={classes.alltext}>
        <Box px={8}>
          <Typography
            variant="h2"
            className={classes.text}
            component="h1"
            display="inline"
          >
            関西学院大学総合政策学部
          </Typography>
        </Box>
        <Box px={8}>
          <Typography
            variant="h1"
            className={classes.text}
            component="h1"
            display="inline"
          >
            キリスト教学A 課題レポート確認ツール
          </Typography>
        </Box>
        <Box pt={4}>
          <Typography
            variant="h6"
            className={classes.text}
            component="h1"
            display="inline"
          >
            WordのコピペOK. 作成したものを下に貼付.
            データは取得せず、みなさんのパソコン内で処理をしています。この結果と教授の提出物の採点に相違があっても、こちらは一切の責任を追いません。
            テキストを入力した場合は自動でチェックされますが、コピペをした場合はチェックされないので、下のCHECKボタンを押してください。CHECKボタンの下に結果が出ます。
          </Typography>
          {/* <Typography variant="body1"  className={classes.text} component="h1" display="inline">
            今後の参考にするため、何かこのサービスについてうまくいかないなどの問題点や意見がある場合はこちらまでメールください。迷惑メールスクレイピング防止のため、[at]を@に変更してご使用ください。
            keito.12.10[at]icloud.com
          </Typography> */}
        </Box>
        <Box pt={4}>
          <Paper elevation={3} className={classes.panel}>
            <textarea
              className="form-control"
              id="textareas"
              rows="15"
              value={value}
              onChange={handleChange}
            />
          </Paper>
        </Box>
        <Box mt={4} className={classes.tl}>
          <Button variant="contained" color="primary" onClick={handleClick}>
            Check
          </Button>
        </Box>
        <Box className={classes.ok} mt={3}>
          {error ? (
            <div>
              <ReportProblemIcon
                style={{ color: red[500], fontSize: 64, display: "inline" }}
              ></ReportProblemIcon>
              <Typography
                variant="h3"
                className={classes.text1}
                component="h2"
                display="inline"
              >
                {error}
              </Typography>
              <Box pt={8}>
                <Typography
                  variant="body1"
                  className={classes.text}
                  component="h2"
                  display="inline"
                >
                  キリスト教学Aの課題レポートは、制限が厳しく、作者は-15点になりそうので、今後そんな過ちを犯さないようにということで１時間で作りました。
                  ３時間のクオリティなのでめちゃくちゃ適当です。
                </Typography>
              </Box>
            </div>
          ) : (
            <div>
              <CheckIcon
                style={{ color: indigo.A700, fontSize: 64 }}
              ></CheckIcon>
              <Typography
                variant="h3"
                className={classes.text1}
                component="h2"
                display="inline"
              >
                OK Check Passed.
              </Typography>

              <Box pt={2}>
                <Typography
                  variant="body1"
                  className={classes.textex}
                  component="h2"
                >
                  -
                  字数が書かれているか、冒頭に学籍番号、名前が書かれているか、全角英数が使われていないかの確認を行いました。
                  <br></br>
                </Typography>
              </Box>
              <Box pt={2}>
                <Typography
                  variant="body1"
                  className={classes.textred}
                  component="h2"
                >
                  以下は機械でするのがめんどくさかったため、自分でご確認お願いします。
                  <br></br>-
                  課題番号に応じて【1】【2】などの見出しをつけて回答を記すこと
                  <br></br>- 指定文字数を守っているか<br></br>-
                  箇条書きではなく文章で回答する<br></br>-
                  授業担当者が指定した資料・情報源以外の資料・情報源を参考にして回答した場合のみ、課題の文字数とは別に、その資料・情報源の情報（著者、タイトル、URL
                  等）を記す<br></br>
                </Typography>
              </Box>
              <Box mt={4} mb={2}>
                <Typography
                  variant="body1"
                  className={classes.text}
                  component="h2"
                  display="inline"
                >
                  以上のことが確認できましたら、以下のコピーボタンを押してください。
                  押した後はそのままLunaのページに行き、「テキスト情報の入力」から入力してください。
                  こちらのシステムを利用した場合、書式の削除を行う必要はありません。
                </Typography>
              </Box>
              <Button variant="contained" color="primary" onClick={copy}>
                {copytext}
              </Button>
            </div>
          )}
        </Box>
      </Box>
    </div>
  );
}
