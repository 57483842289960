import './App.css';
import Check from './Check';
import { BrowserRouter as Router, Route } from "react-router-dom";

function App() {
  return (
    <Router>
   <Route path="/" component={Check}></Route>
   </Router>
  );
}

export default App;